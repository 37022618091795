/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin dot($left) {
    background-color: $font-color-2;
    border-radius: 7px;
    content: '';
    height: 7px;
    position: absolute;
    left: $left;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;

    @include mobile {
        background-color: $font-color-3;
    }
}

[dir="ltr"] .ProductActions {

    &-AddToCartWrapper {
        &_isGrouped {
            .Button {
                margin-right: 0;
                max-width: 100%;
            }
        }
    }
}

.ProductActions {
    .PickUpFromBookstore {
        margin-top: 32px;
    }

    &-AddToCartWrapper {
        align-items: flex-start;

        inpost-izi-button {
            margin-top: 0 !important;
        }
    }

    &-AddToCart {
        max-width: 248px;

        @include mobile {
            max-width: 100%;
            flex-basis: 65%;
            flex-grow: 1;
        }
    }

    &-AddToCartFixed {
        align-items: center;

        .hideOnScroll & {
            transform: translateY(0);
        }

        @include mobile {
            border-radius: 4px 4px 0 0;
            border-top: 0;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
            bottom: 62px;
            padding: 10px 15px;
            flex-wrap: wrap;
            justify-content: space-between;

            .Field {
                &-Wrapper {
                    &_type {
                        &_numberWithControls {
                            flex-basis: 35%;

                            button {
                                height: 32px;
                                width: 32px;
                            }

                            input {
                                width: 47px;
                            }

                            > .ProductActions-Qty {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        &_isIos {
            @include mobile {
                bottom: 75px;

                .AddIcon,
                .MinusIcon {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }

    &-AddToCartWrapper {
        margin-bottom: 12px;
        margin-top: 12px;

        @include desktop {
            min-height: 48px;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_numberWithControls {
                        button {
                            height: 48px;
                            width: 48px;
                        }

                        input {
                            width: 0
                        }
                    }
                }
            }
        }
    }

    &-AskAboutSection {
        line-height: inherit;
        order: 3;

        .AskAboutProduct {
            &-Wrapper {
                margin: 0;

                @include mobile {
                    button {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &-Banner {
        margin-top: 12px;
    }

    &-CustomPriceInfo {
        color: $font-color-1;

        @include mobile {
            font-size: 12px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    &-DescriptionList {
        color: $font-color-2;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include mobile {
            margin-bottom: 16px;
            margin-top: 15px;
        }

        dt {
            float: left;
            margin-right: 10px;
        }

        dd {
            font-weight: 600;
        }

        &>* {
            margin-bottom: 6px;
        }
    }

    &-DiscountAmount {
        color: $font-color-2;

        &::after {
            display: none;
        }
    }

    &-GroupedItems {
        .ProductPrice {

            &-Omnibus {
                color: $font-color-2;
                font-size: 14px;
                margin-top: 4px;
                text-align: right;
            }
        }
    }

    &-GroupedTotalPrice {
        color: $font-color-2;
        margin-top: 10px;
        text-align: right;

        span {
            display: inline-block;
            font-size: 24px !important;
            margin-left: 4px;
        }

        .ProductActions-CustomPriceInfo {
            color: $font-color-1;
            font-size: 12px;
            margin-top: 4px;
            font-weight: 400;

            @include mobile {
                font-size: 10px;
                font-weight: 400;
            }
        }

        .ProductPrice {

            &-HighPrice {
                font-size: 20px !important;
                text-decoration: line-through;
            }
        }
    }

    &-NotifyButton {
        margin: 12px 0;
    }

    &-Omnibus {
        color: $font-color-2;

        @include mobile {
            font-size: 12px;
        }
    }

    &-PayPoBanner {
        align-items: center;
        border-top: 1px $neutral-color-6 solid;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        margin-top: 18px;
        padding: 12px 0;

        img {
            height: 19px;
            margin-right: 10px;
            width: 70px;
        }
    }

    &-PriceAdditionalInfo {
        color: $font-color-2;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        gap: 27px;
        line-height: 24px;
        margin-top: 4px;

        @include mobile {
            flex-direction: column;
            gap: 4px;
            margin-bottom: 16px;
        }

        span {
            &::after {
                @include dot(-17px);

                @include desktop {
                    display: none;
                }

                @include mobile {
                    display: none;
                }
            }
        }
    }

    &-PriceWrapper {
        position: relative;
        @include mobile {
            margin-bottom: 4px;
        }

        @include desktop {
            min-height: 40px;
        }
    }

    &-ProductTagsList {

        @include mobile {
            margin-bottom: 32px;
        }
    }

    &-Schema {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &::after {
            content: '';
            flex: 1;
        }
    }

    &-PriceInfoIcon {
        margin-left: 6px;
        vertical-align: middle;
        cursor: pointer;
    }

    &-Tooltip {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(8px, -50%);
        background-color: #fff;
        color: #000;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        z-index: 1000;
        width: auto;
        min-width: 300px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        white-space: normal;
        word-wrap: break-word;
        display: none;
        font-size: 10px;
        line-height: 16px;

        &[data-visible="true"] {
            display: block;
        }

        @include mobile {
            transform: translate(10%, -50%);
            white-space: normal;
            word-wrap: break-word;
            text-align: left;
            box-sizing: border-box;
        }

        @media (max-width: 1000px) {
            min-width: 200px;
        }

        @media (max-width: 810px) {
            min-width: 300px;
            transform: translate(5%, -50%)
        }

        @media (max-width: 510px) {
            min-width: 250px;
        }

        @media (max-width: 460px) {
            min-width: 150px;
        }

        @media (max-width: 350px) {
            min-width: 50px;
        }
    }

    &-Qty {
        height: 48px;
        margin-right: 30px !important;

        @include mobile {
            height: 32px;
        }

        input {
            font-size: 18px !important;
            font-weight: 600;
            line-height: 24px;
            min-width: 56px;

            @include mobile {
                font-size: 16px !important;
            }
        }
    }

    &-Review {
        bottom: 1px;

        @include mobile {
            bottom: unset;
            padding-left: 19px;
            line-height: 24px;
            display: flex;
            align-content: center;
            justify-content: center;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    &-ReviewText {
        color: $extra-color-1;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        @include mobile {
            font-size: 12px;
        }

        &:hover {
            color: $primary-color-1;
        }

    }

    &-Reviews {
        align-items: center;
        gap: 31px;
        margin-bottom: 30px;

        @include mobile {
            margin-bottom: 0;
            gap: 6px;
            flex-wrap: wrap;
        }

        &>*:not(:first-child)::before {
            @include dot(4px);

            @include desktop {
                @include dot(-19px);
            }
        }

        & .ProductReviewRating {
            color: $font-color-2;
            font-size: 16px;
            font-weight: 600;
            margin-right: 0 !important;

            @include mobile {
                font-size: 16px;
                top: -1px;
            }
        }

        .StarIcon {
            height: 20px;
            width: 20px;

            @include mobile {
                height: 18px;
                width: 18px;
            }
        }

        .ProductActions-AskAboutSection {
            @include mobile {
                padding-left: 19px;
            }

            .Button_textBtn {
                line-height: 24px;
            }
        }
    }

    &-Section {
        &_type_sku {
            @include mobile {
                align-items: flex-start;
            }

            @include desktop {
                min-height: 24px;
            }
        }
    }

    &-ShippingDate {
        align-items: center;
        color: $font-color-2;
        display: flex;
        font-size: 16px;
        font-weight: 600;

        svg {
            margin-right: 10px;
        }

        time {
            color: $primary-color-1;
        }
    }

    &-StatusInformation {
        background-color: $orange-color-1;
        display: flex;
        font-size: 14px;
        line-height: 20px;
        padding: 14px 20px 14px 22px;
        border: 1px solid $yellow-status-color;
        margin-bottom: 6px;
        font-weight: 400;
        border-radius: 2px;
        width: fit-content;

        @include desktop {
            align-items: center;
        }

        &:last-child {
            margin-bottom: 18px;
        }

        &_isZeroPricePresaleProduct {
            border-color: $red-color-3;
            background-color: $red-color-4;

            svg {
                path {
                    stroke: $extra-color-10;
                }
            }
        }

        div {
            margin-left: 12px;

            b {
                margin-left: 4px;
            }
        }
    }

    &-Stock {
        align-items: center;
        background: $red-color-1;
        border-radius: 24px;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        min-height: 24px;
        padding: 0 8px 0 24px;

        @include mobile {
            font-size: 16px;
            margin-bottom: 5px;
        }

        &::before {
            background: $red-color-2;
            border-radius: 10px;
            content: '';
            height: 10px;
            left: 8px;
            position: absolute;
            width: 10px;
        }

        &_status {
            background: $green-color-1;

            &::before {
                background: $green-color-2;
            }

            &_announcements,
            &_presale,
            &_to_order {
                background: $orange-color-1;

                &::before {
                    background: $orange-color-2;
                }
            }
        }

        &_onlyBookstore {
            background: $orange-color-1;

            &::before {
                background: $orange-color-2;
            }
        }
    }

    &-Title {
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 6px;

        @include mobile {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .ProductPrice {
        .ProductPrice {
            &-PriceValue {
                color: $font-color-1;
                font-size: 36px;
                font-weight: 600;
                margin-right: 8px;
            }
        }

        &_hasDiscount {
            .ProductPrice {
                &-HighPrice {
                    color: $font-color-2;
                    font-size: 18px;
                    font-weight: 400;
                    opacity: 1;
                }

                &-PriceValue {
                    color: $primary-color-1;
                    font-size: 36px;
                    font-weight: 600;
                    margin-right: 8px;
                }
            }
        }
    }

    &-GroupedItems {
        margin-top: 0;

        @include mobile {
            margin-top: 24px;
        }

        .ProductPrice {
            min-height: auto;

            .ProductPrice {
                &-HighPrice {
                    font-size: 16px;
                    margin-right: 6px;

                    @include mobile {
                        font-size: 16px;
                    }
                }

                &-PriceValue {
                    font-size: 24px;
                    font-weight: 600;
                    margin-right: 0;
                    margin-top: 0;

                    @include mobile {
                        font-size: 18px;
                        margin-right: 8px;

                        @media screen and (max-width: 367px) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }

    &_isGrouped {
        .ProductActions {
            &-PayPoBanner {
                border-top: 0;
                margin-bottom: 18px;

                @include mobile {
                    margin: 10px 0;
                    padding: 0;
                }
            }

            &-Stock {
                display: none;
            }
        }
    }

    .AskAboutProduct {
        @include mobile {
            &-Wrapper {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }
}
